//import {split} from "vue-notification/src/util";

function getUserPermissions (user) {
    let perms = [];
    if(user.roles) {
        user.roles.forEach((role) => {
            perms = role.permissions.filter(perms => perms.id).map((perms) => { return perms.name.toLowerCase() })
        })
    }
    return perms
}

export const checkUserPermissions = (user, check_perms) => {
    let check = false
    getUserPermissions(user).forEach((perms) => {
        if (check_perms.includes(perms)) check = true
    })
    return check
}

function getUserRoles (user) {
    let roles = [];
    if(user.roles) {
        user.roles.forEach((role) => {
            roles = role.filter(record => record.id).map((record) => { return record.name.toLowerCase() })
        })
    }
    return roles
}

export const checkUserRoles = (user, check_roles) => {
    let check = false
    getUserRoles(user).forEach((role) => {
        if (check_roles.includes(role)) check = true
    })
    return check
}


export const QR2NIC = (nic) => {
    let nicParts = nic.split('/')
    let newNic = null;
    if(nicParts.length > 12) {
        newNic = nicParts[0].substr(2, 12)
    } else {
        newNic = nicParts[0]
    }
    // if(nic.length > 12) {
    //     nic = nic.split('/')[0]
    //     nic = nic.substr(2, 12)
    // }
    return newNic;
}
