import axios from "@/plugins/axios";

export default {
    async index( url, params ){
        return await axios.get( url, params )
    },

    async show( url, id ){
        return await axios.get( url + '/' + id );
    },

    async update( url, id, data ){
        return await axios.put( url + '/' + id, data );
    },

    async create( url, data ){
        return await axios.post( url, data );
    },

    async delete( url, id ){
        return await axios.delete( url + '/' + id )
    }
}
