<template>
  <div>
    <Breadcrumbs title="Classrooms"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button v-show="checkUserPermissions(user,['create_classrooms'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Classroom</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>



              <div class="row" v-if="false">
                <div class="col-md-9 delete-datatablex">
                  <b-input-group class="datatable-btnx m-t-10">
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearSearch" class="btn-square btn-dark">Clear</b-button>
                      <b-button :disabled="!filter" @click="clearSearch" class="btn-square btn-success">Add Record</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="col-12 col-md-3 text-right m-t-10">
                  <b-form-group label-cols="9" label="Per Page" class="datatable-selectx">
                    <b-form-select class="btn-square border" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(status)="row" class="text-center">
                    <strong v-if="row.item.status === 0" class="text-success p-2">YES</strong>
                    <strong v-else class="text-error">NO</strong>
                  </template>
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['update_classrooms'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_classrooms'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>



              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="formModal.id" label="Id">
                  <b-form-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-form-input>
                  <b-form-input v-else v-model="formModal.id" readonly></b-form-input>
                </b-form-group>

                <b-form-group label="Institute *">
                  <b-form-select :disabled="formModal.id" v-model="formModal.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group label="Classroom Name *">
                  <b-form-input v-model="formModal.name" required></b-form-input>
                </b-form-group>

                <b-form-group label="Capacity">
                  <b-form-input type="number" v-model="formModal.capacity" required></b-form-input>
                </b-form-group>


              </b-modal>
              <!-- End Create Modal -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
export default {
  name: "classrooms",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes'
    })
  },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/classrooms',
      tableFields: [
        { key: 'institute.name', label: 'Institute', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'capacity', label: 'Capacity', sortable: true, class: 'text-right' },
        { key: 'status', label: 'Available', sortable: true, class: 'text-right' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        id: null,
        name: null,
        institute_id: null,
        capacity: 1,
        status: 0
      },

      selectedInstitute: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() {
      this.formModal.id = null
      this.formModal.index = null
      this.formModal.name = null
      this.formModal.capacity = 1
      this.formModal.status = 0
      this.formModal.institute_id = null
    },
    // Create Record Modal
    showCreateModal(button) {
      this.clearFormModals()
      this.form_modal_title = "New Classroom"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.formModal.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, {
        name: this.formModal.name,
        institute_id: this.formModal.institute_id,
        capacity: this.formModal.capacity,
        status: this.formModal.status
      })
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Classroom added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.formModal.index = index
      this.formModal.id = item.id
      this.formModal.name = item.name
      this.formModal.status = item.status
      this.formModal.capacity = item.capacity
      this.formModal.institute_id = item.institute_id
      this.form_modal_title = "Edit Classroom"
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.formModal.id, {
        id: this.formModal.id,
        name: this.formModal.name,
        status: this.formModal.status,
        capacity: this.formModal.capacity,
        institute_id: this.formModal.institute_id,
      }).then((resp) => {
        this.$toasted.success("Classroom updated successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while updating data")
      }).finally(() => {
        this.fetchData()
      })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id).then((resp) => {
        this.$toasted.success("Classroom removed successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while removing data")
      }).finally(() => {
        this.fetchData()
      })
    }
  }
}
</script>

<style scoped>

</style>
